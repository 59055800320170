import { usePrefetchContext } from "../context/usePrefetchContext";
import { useRefetchContext } from "../context/useRefetchContext";
import { useGetShifts } from "./useGetShifts";
import { useLocation } from "../../store/location.store";
import { useSeniority } from "../../store/seniority.state";
import { formatDateAPI } from "../../utils/formatTime";

const useHandleOnScheduleAdd = ({
  activeDate,
  prevDate,
  nextDate,
  shouldRefetchShiftRequests = true,
  setTriggerSearchReset,
}: {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
  shouldRefetchShiftRequests?: boolean;
  setTriggerSearchReset?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  // add this with prefetch
  // const remainingShiftData = useGetShiftsLocationsSeniorities({ activeDate });

  const { setRefetchMap } = useRefetchContext();

  const { triggerStatsPrefetch } = usePrefetchContext();

  const { refetch: refetchShifts, rawData } = useGetShifts({
    activeDate,
    prevDate,
    nextDate,
  });

  const shiftsData: { _id: string }[] | undefined = Object.values(
    rawData
  ).every((data) => !data)
    ? undefined
    : (Object.values(rawData)
        .map((data) => {
          return data ? Object.values(data) : [];
        })
        .flat(2) as { _id: string }[]);

  return async () => {
    refetchShifts();

    const doctorSeniority: Record<string, boolean> = {};
    const doctorSuggestion: Record<string, boolean> = {};
    const shiftRequests: Record<string, boolean> = {};

    if (shiftsData && shiftsData.length > 0) {
      shiftsData.flat().forEach((shift: { _id: string }) => {
        [1, 2, 3].forEach((seniority) => {
          doctorSeniority[
            JSON.stringify({
              shiftId: shift._id,
              seniority,
            })
          ] = true;
        });

        doctorSuggestion[
          JSON.stringify({
            shiftId: shift._id,
          })
        ] = true;
      });
    }

    if (shouldRefetchShiftRequests) {
      shiftRequests[
        JSON.stringify({
          locationId: activeLocationId,
          date: formatDateAPI(activeDate),
          seniority: activeSeniorityId,
        })
      ] = true;
    }

    setRefetchMap((refetchMap) => ({
      ...refetchMap,
      doctorSeniority: { ...refetchMap.doctorSeniority, ...doctorSeniority },
      doctorSuggestion: { ...refetchMap.doctorSuggestion, ...doctorSuggestion },
      shiftRequests: { ...refetchMap.shiftRequests, ...shiftRequests },
    }));
    // triggerDoctorPrefetch();
    triggerStatsPrefetch();
    if (setTriggerSearchReset) {
      setTriggerSearchReset((trigger) => !trigger);
    }
  };
};

export default useHandleOnScheduleAdd;
