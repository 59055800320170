import { useCallback, useEffect } from "react";

import { useGetTagsQuery } from "../../../store/doctorApi";
import { useGetGroupsQuery } from "../../../store/groupApi.ts";
import {
  useGetDoctorsFilterQuery,
  useGetDoctorsSuggestionQuery,
} from "../../../store/rosterApi";
import { useEstimationContext } from "../../../hooks/context/useEstimationContext";
import useHandleOnScheduleAdd from "../../../hooks/estimation/handleOnScheduleAdd";
import { useRefetchContext } from "../../../hooks/context/useRefetchContext.tsx";

export const useDoctors = ({
  shiftId,
  activeTab,
  doctorFilterSeniority,
}: {
  shiftId: string | undefined;
  activeTab: "seniority" | "suggested" | "tags";
  doctorFilterSeniority: number;
}) => {
  const {
    data: doctorsData,
    isLoading: isDoctorsLoading,
    isFetching: isDoctorsFetching,
    refetch: refetchDoctorFilter,
  } = useGetDoctorsFilterQuery(
    {
      shiftId: activeTab === "tags" ? undefined : shiftId,
      seniority: activeTab === "tags" ? 4 : doctorFilterSeniority,
    },
    { skip: activeTab === "suggested" }
  );

  const {
    data: suggestedDoctorsData,
    isLoading: isDoctorsSuggestionsLoading,
    isFetching: isDoctorsSuggestionsFetching,
    refetch: refetchDoctorSuggestion,
  } = useGetDoctorsSuggestionQuery(
    { shiftId: shiftId },
    { skip: !shiftId || activeTab !== "suggested" }
  );

  return {
    isLoading: isDoctorsLoading || isDoctorsSuggestionsLoading,
    isFetching: isDoctorsFetching || isDoctorsSuggestionsFetching,
    refetchDoctorFilter,
    refetchDoctorSuggestion,
    doctors: activeTab === "suggested" ? suggestedDoctorsData : doctorsData,
  };
};

const useData = ({
  activeDate,
  prevDate,
  nextDate,
  searchQuery,
  activeGroups,
  activeSubGroups,
  activeTags,
  setTriggerSearchReset,
}: {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
  searchQuery: string;
  activeGroups: { _id: string }[];
  activeSubGroups: { _id: string }[];
  activeTags: string[];
  setTriggerSearchReset: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { editShiftId, doctorFilterSeniority, activeTab } =
    useEstimationContext();

  const { refetchMap, setRefetchMap } = useRefetchContext();

  const {
    isLoading,
    isFetching,
    refetchDoctorFilter,
    refetchDoctorSuggestion,
    doctors,
  } = useDoctors({
    shiftId: editShiftId,
    doctorFilterSeniority,
    activeTab,
  });

  useEffect(() => {
    if (activeTab === "seniority") {
      const args = {
        shiftId: editShiftId,
        seniority: doctorFilterSeniority,
      };
      if (refetchMap.doctorSeniority[JSON.stringify(args)]) {
        refetchDoctorFilter();
        refetchMap.doctorSeniority[JSON.stringify(args)] = false;
        setRefetchMap({
          ...refetchMap,
          doctorSeniority: refetchMap.doctorSeniority,
        });
      }
    } else if (activeTab === "tags") {
      const args = {
        shiftId: undefined,
        seniority: 4,
      };
      if (refetchMap.doctorSeniority[JSON.stringify(args)]) {
        refetchDoctorFilter();
        refetchMap.doctorSeniority[JSON.stringify(args)] = false;
        setRefetchMap({
          ...refetchMap,
          doctorSeniority: refetchMap.doctorSeniority,
        });
      }
    } else {
      const args = { shiftId: editShiftId };
      if (refetchMap.doctorSuggestion[JSON.stringify(args)]) {
        refetchDoctorSuggestion();
        refetchMap.doctorSuggestion[JSON.stringify(args)] = false;
        setRefetchMap({
          ...refetchMap,
          doctorSuggestion: refetchMap.doctorSuggestion,
        });
      }
    }
  }, [refetchMap, editShiftId, activeTab, doctorFilterSeniority]);

  const { isLoading: isGroupsLoading, data: groups } = useGetGroupsQuery({
    seniority: doctorFilterSeniority,
  });

  const { data: tags, isLoading: isTagsLoading } = useGetTagsQuery({});

  const doctorFilerFn = useCallback(
    (doctor: {
      subGroup: any;
      tags: any;
      group: any;
      user: { name: string };
    }) => {
      const searchBoolean =
        searchQuery === "" ||
        doctor.user.name.toLowerCase().startsWith(searchQuery.toLowerCase());

      if (activeTab === "suggested") {
        return searchBoolean;
      }

      if (activeTab === "seniority") {
        const groupBoolean =
          activeGroups.length === 0 ||
          activeGroups.find((group) => {
            return doctor.group && group._id === doctor.group._id;
          });

        const subGroupBoolean =
          activeSubGroups.length === 0 ||
          activeSubGroups.find((subGroup) => {
            return doctor.subGroup && subGroup._id === doctor.subGroup._id;
          });

        return searchBoolean && groupBoolean && subGroupBoolean;
      } else {
        const tagsBoolean =
          activeTags.length === 0 ||
          activeTags.some((tag) =>
            doctor.tags.find(
              (tagObj: { value: string }) => tagObj.value === tag
            )
          );

        if (activeTab === "tags") {
          return searchBoolean && tagsBoolean;
        }
      }
    },
    [activeGroups, activeTab, activeSubGroups, activeTags, searchQuery]
  );

  const filteredDoctors = doctors?.filter(
    (doctor: {
      subGroup: any;
      tags: any;
      group: any;
      user: { name: string };
    }) => {
      return doctorFilerFn(doctor);
    }
  );

  const displayType = (() => {
    if (isLoading) {
      return "loading";
    }
    if (activeTab === "suggested" && !editShiftId) {
      return "selectShift";
    }
    return filteredDoctors && filteredDoctors.length > 0
      ? "displayData"
      : "noData";
  })();

  const handleOnScheduleAdd = useHandleOnScheduleAdd({
    activeDate,
    prevDate,
    nextDate,
    setTriggerSearchReset,
  });

  return {
    isDoctorsLoading: isLoading,
    isDoctorsFetching: isFetching,
    displayType,
    isGroupsLoading,
    isTagsLoading,
    doctors: filteredDoctors,
    tags,
    groups,
    handleOnScheduleAdd,
  };
};

export default useData;
