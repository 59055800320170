import { usePrefetchContext } from "../context/usePrefetchContext";
import { useRefetchContext } from "../context/useRefetchContext";
import { useGetShifts } from "./useGetShifts";

const useHandleOnDayOffRequestProcess = ({
  activeDate,
  prevDate,
  nextDate,
}: {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
}) => {
  const { setRefetchMap } = useRefetchContext();

  const { triggerStatsPrefetch } = usePrefetchContext();

  const { data: shiftsData } = useGetShifts({
    activeDate,
    prevDate,
    nextDate,
  });

  return async () => {
    const doctorSeniority: Record<string, boolean> = {};
    const doctorSuggestion: Record<string, boolean> = {};

    if (shiftsData && shiftsData.length > 0) {
      shiftsData.flat().forEach((shift: { _id: unknown }) => {
        [1, 2, 3].forEach((seniority) => {
          doctorSeniority[
            JSON.stringify({
              shiftId: shift._id,
              seniority,
            })
          ] = true;
        });

        doctorSuggestion[
          JSON.stringify({
            shiftId: shift._id,
          })
        ] = true;
      });
    }

    setRefetchMap((refetchMap) => ({
      ...refetchMap,
      doctorSeniority: { ...refetchMap.doctorSeniority, ...doctorSeniority },
      doctorSuggestion: { ...refetchMap.doctorSuggestion, ...doctorSuggestion },
    }));
    triggerStatsPrefetch();
  };
};

export default useHandleOnDayOffRequestProcess;
