import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useEffect, useMemo, useRef, useState } from "react";
import { stringSimilarity } from "string-similarity-js";

import HorizontalScrollContainer from "../HorizontalScrollContainer";
import RoundedTickOutline from "../Icons/RoundedTickOutline";
import RoundedCancelOutline from "../Icons/RoundedCancelOutline";
import Rivet from "../Icons/Rivet";

import {
  useCreateSlotFilterMutation,
  useDeleteSlotFilterMutation,
  useUpdateSlotFilterMutation,
  useUpdateSlotLabelsMutation,
} from "../../store/shiftsApi";

import { Doctor } from "../../interface/doctor";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import useKey from "../../hooks/useKey";

import { useDoctors } from "./DoctorFilterWindow/data";
import SlotPill from "./SlotPill";
import FilterSlot from "./FilterSlot";

import { useSeniority } from "../../store/seniority.state";
import { useGetScheduleLabelsQuery } from "../../store/preferencesApi";
import {
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
} from "../../store/rosterApi";
import { useEstDndContext } from "../../hooks/context/useEstDndContext";
import handleResponse from "../../utils/handleResponse";
import Delete from "../Icons/Delete";
import { Filter } from "../../interface/shift";
import isStringArrayEqual from "../../utils/stringArrayEq";

const getName = (doctor: {
  doctor: Doctor;
  scoreName: number;
  scoreNickName: number;
}): string => {
  const type = doctor.scoreNickName >= doctor.scoreName ? "nickName" : "name";
  return doctor.doctor.user[type] ?? "";
};

const extractFilterValues = (
  filter: Required<Omit<Filter, "_id" | "seniority">> & { seniority?: number }
): Omit<Filter, "_id" | "doctors"> & { doctors?: string[] } => {
  const type = filter.doctors.length > 0 ? "doctor" : "rest";

  return {
    ...(filter.seniority && type === "rest"
      ? { seniority: filter.seniority }
      : {}),
    ...(filter.groups.length > 0 && type === "rest"
      ? { groups: filter.groups }
      : {}),
    ...(filter.subGroups.length > 0 && type === "rest"
      ? { subGroups: filter.subGroups }
      : {}),
    ...(filter.tags.length > 0 && type === "rest" ? { tags: filter.tags } : {}),
    ...(filter.experience > 0 && type === "rest"
      ? { experience: filter.experience }
      : {}),
    ...(filter.efficiency > 0 && type === "rest"
      ? { efficiency: filter.efficiency }
      : {}),
    ...(type === "doctor"
      ? { doctors: filter.doctors.map((doctor) => doctor._id) }
      : {}),
  };
};

export type InputSlotMode = "doctor" | "filter" | "close";

const InputSlot = ({
  setMode,
  mode,
  shiftId,
  schedule,
  filter,
  labels,
  index,
  onScheduleAdd,
  triggerOnEnter,
}: {
  setMode: (mode: InputSlotMode) => void;
  mode: InputSlotMode;
  shiftId: string;
  schedule: any | undefined;
  filter: Filter | undefined;
  labels?: any[];
  index: number;
  onScheduleAdd: () => void;
  triggerOnEnter: (index: number) => void;
}) => {
  const { doctorFilterSeniority, activeTab } = useEstimationContext();

  const [query, setQuery] = useState<string>(
    schedule?.doctor.user?.nickName ?? schedule?.doctor.user?.name ?? ""
  );
  const [selectedLabels, setSelectedLabels] = useState<string[]>(
    labels?.map((label) => label._id) ?? []
  );

  const { activeId: activeSeniorityId } = useSeniority();

  const [createSlotFilter, { isLoading: isCreateSlotFilterLoading }] =
    useCreateSlotFilterMutation();

  const [updateSlotFilter, { isLoading: isUpdateSlotFilterLoading }] =
    useUpdateSlotFilterMutation();

  const [deleteSlotFilter, { isLoading: isDeleteSlotFilterLoading }] =
    useDeleteSlotFilterMutation();

  const [updateSlotLabels, { isLoading: isUpdateSlotLabelsLoading }] =
    useUpdateSlotLabelsMutation();

  const [filterState, setFilterState] = useState<{
    seniority?: number;
    groups: string[];
    subGroups: string[];
    tags: string[];
    experience: number;
    efficiency: number;
  }>({
    seniority: filter ? filter.seniority : activeSeniorityId,
    groups: filter?.groups ? [...filter.groups] : [],
    subGroups: filter?.subGroups ? [...filter.subGroups] : [],
    tags: filter?.tags ? [...filter.tags] : [],
    experience: filter?.experience ?? 0,
    efficiency: filter?.efficiency ?? 0,
  });

  useEffect(() => {
    setActiveFilterDoctors({
      ...activeFilterDoctors,
      [`${shiftId}-${index}`]: filter?.doctors ?? [],
    });
  }, [mode]);

  const { activeFilterDoctors, setActiveFilterDoctors } = useEstDndContext();

  const inputRef = useRef<null | HTMLInputElement>(null);
  const lockAdd = useRef<boolean>(false);

  const { isKeyPressed, setKeyPressed } = useKey(inputRef, [
    "Tab",
    "Enter",
    "Escape",
  ]);

  const { isLoading: isDoctorsLoading, doctors } = useDoctors({
    shiftId,
    doctorFilterSeniority,
    activeTab,
  });

  const doctorsToSearch = schedule
    ? doctors
    : doctors?.filter(
        (doctor: Doctor) => doctor.availability !== "unavailable"
      );

  const { data: scheduleLabels, isLoading: isScheduleLabelLoading } =
    useGetScheduleLabelsQuery({
      seniority: activeSeniorityId,
    });

  const [createSchedule, { isLoading: isCreateScheduleLoading }] =
    useCreateScheduleMutation();

  const [updateSchedule, { isLoading: isUpdateScheduleLoading }] =
    useUpdateScheduleMutation();

  const hintDoctor = useMemo(() => {
    if (query !== "") {
      const nickNameDoctor = {
        doctor: doctorsToSearch?.filter((doctor: Doctor) =>
          doctor.user.nickName?.toLowerCase().startsWith(query.toLowerCase())
        )[0],
        type: "nickName",
      };

      if (nickNameDoctor.doctor) {
        return nickNameDoctor;
      }

      const nameDoctor = {
        doctor: doctorsToSearch?.filter((doctor: Doctor) =>
          doctor.user.name.toLowerCase().startsWith(query.toLowerCase())
        )[0],
        type: "name",
      };

      if (nameDoctor.doctor) {
        return nameDoctor;
      }
    }
    return { doctor: undefined, type: "none" };
  }, [doctors, query]);

  const autoCorrectDoctors = useMemo(() => {
    if (query !== "" && !hintDoctor.doctor) {
      const newDoctors:
        | Array<{
            doctor: Doctor;
            scoreName: number;
            scoreNickName: number;
          }>
        | undefined = doctorsToSearch?.map((doctor: Doctor) => ({
        doctor,
        scoreNickName: stringSimilarity(
          query.toLowerCase(),
          doctor.user.nickName?.toLowerCase() ?? "",
          query.length < 4 ? 1 : 2
        ),
        scoreName: stringSimilarity(
          query.toLowerCase(),
          doctor.user.name.toLowerCase(),
          query.length < 4 ? 1 : 2
        ),
      }));

      if (!newDoctors) {
        return;
      }

      newDoctors.sort(
        (
          a: { scoreName: number; scoreNickName: number },
          b: { scoreName: number; scoreNickName: number }
        ) => {
          return (
            b.scoreName + b.scoreNickName - (a.scoreName + a.scoreNickName)
          );
        }
      );

      return newDoctors.filter((doctor) => {
        return (doctor.scoreName + doctor.scoreNickName) / 2 > 0.1;
      });
    }

    return undefined;
  }, [doctors, hintDoctor, query]);

  const autoCompleteText: string | undefined =
    query !== ""
      ? hintDoctor.doctor?.user?.[hintDoctor.type]?.slice(query.length)
      : undefined;

  useEffect(() => {
    if (isKeyPressed["Tab"] && hintDoctor.doctor) {
      setQuery(hintDoctor.doctor?.user?.[hintDoctor.type]);
      return;
    }
    if (
      isKeyPressed["Tab"] &&
      autoCorrectDoctors &&
      autoCorrectDoctors.length > 0
    ) {
      setQuery(getName(autoCorrectDoctors[0]));
      return;
    }
    if (isKeyPressed["Enter"]) {
      handleSubmit(() => {
        onScheduleAdd();
        setMode("close");
        triggerOnEnter(index);
      });
      return;
    }
    if (isKeyPressed["Escape"]) {
      setMode("close");
      resetState();
      setKeyPressed({ ...isKeyPressed, Escape: false });
    }
  }, [hintDoctor, isKeyPressed, autoCorrectDoctors]);

  const handleSubmit = async (successFunction: () => void) => {
    if (!lockAdd.current) {
      lockAdd.current = true;
      const toAddLabels =
        (!labels && selectedLabels.length > 0) ||
        (labels &&
          !isStringArrayEqual(
            labels.map((label) => label._id),
            selectedLabels
          ));
      if (toAddLabels) {
        const response = await updateSlotLabels({
          shiftId,
          labels: selectedLabels,
          slotIndex: index,
        });
        handleResponse(response, "Schedule Labels Updated.", successFunction);
      }
      if (hintDoctor.doctor && hintDoctor.doctor._id !== schedule?.doctor?._id) {
        if (schedule) {
          const response = await updateSchedule({
            scheduleId: schedule._id,
            doctorId: hintDoctor.doctor._id,
            slotFilterId: filter?._id,
          });
          handleResponse(response, "Schedule updated", successFunction);
        } else {
          const response = await createSchedule({
            shiftId,
            doctorId: hintDoctor.doctor._id,
            slotIndex: index,
          });
          handleResponse(response, "Schedule added", successFunction);
        }
      } else if (!toAddLabels) {
        toast.warning("Invalid doctor name, please press tab or change name", {
          toastId: "invalid-doctor-name",
        });
      }
      lockAdd.current = false;
    }
  };

  const isAutoCorrect = autoCorrectDoctors && autoCorrectDoctors.length > 0;

  useEffect(() => {
    if (mode === "doctor") {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [mode]);

  const resetState = () => {
    setQuery("");
    setSelectedLabels([]);
  };
  // refactor needed, create two components - filterSlot and inputSlot.
  return (
    <motion.div
      key={`${index}-doctor-input`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      className={`bg-lightGreen3 ${
        isCreateScheduleLoading ||
        isUpdateScheduleLoading ||
        isCreateSlotFilterLoading ||
        isUpdateSlotFilterLoading ||
        isDeleteSlotFilterLoading ||
        isUpdateSlotLabelsLoading
          ? "animate-pulseFast"
          : ""
      } rounded-lg w-full p-3 relative`}
    >
      <div
        className={`flex justify-between ${
          mode === "doctor" ? "border-b-[0.4px] border-b-[#BDBDBD] pb-2" : ""
        } `}
      >
        {" "}
        <div className="flex">
          <div
            className={`${
              mode === "filter" ? "border-y-2 border-secondary rounded-l" : ""
            } w-8 h-[34px] flex items-center`}
          >
            <div
              className={`flex items-center cursor-pointer justify-center px-1.5 h-[110%] w-full ${
                mode === "doctor" ? "" : "bg-secondary"
              } rounded`}
              onClick={() => {
                if (mode === "doctor") {
                  setMode("filter");
                }
              }}
            >
              <Rivet fillColor={mode === "doctor" ? "#67823A" : "white"} />
            </div>
          </div>
          {mode === "filter" && (
            <div
              className={`flex border-y-2 border-r-2 border-secondary p-2 rounded-r gap-2 bg-white`}
            >
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={() => {
                  setActiveFilterDoctors({
                    ...activeFilterDoctors,
                    [`${shiftId}-${index}`]: filter?.doctors ?? [],
                  });
                  setFilterState({
                    seniority: filter ? filter.seniority : activeSeniorityId,
                    groups: filter?.groups ?? [],
                    subGroups: filter?.subGroups ?? [],
                    tags: filter?.tags ?? [],
                    experience: filter?.experience ?? 0,
                    efficiency: filter?.efficiency ?? 0,
                  });
                  setMode("doctor");
                }}
              >
                <RoundedCancelOutline />
              </div>
              <div
                className="flex items-center justify-between cursor-pointer"
                onClick={async () => {
                  if (filter) {
                    const response = await updateSlotFilter({
                      slotFilterId: filter._id,
                      scheduleId: schedule?._id,
                      payload: extractFilterValues({
                        ...filterState,
                        doctors: activeFilterDoctors[`${shiftId}-${index}`],
                      }),
                    });
                    handleResponse(response, "Slot filter updated.", () => {
                      onScheduleAdd();
                      setMode("close");
                    });
                  } else {
                    const response = await createSlotFilter({
                      slotIndex: index,
                      shiftId,
                      payload: extractFilterValues({
                        ...filterState,
                        doctors: activeFilterDoctors[`${shiftId}-${index}`],
                      }),
                    });
                    handleResponse(response, "Slot filter created.", () => {
                      onScheduleAdd();
                      setMode("close");
                    });
                  }
                }}
              >
                <RoundedTickOutline />
              </div>
            </div>
          )}
        </div>
        {mode === "filter" && filter ? (
          <div
            className="flex items-center cursor-pointer"
            onClick={async () => {
              const response = await deleteSlotFilter({
                slotIndex: index,
                shiftId,
                slotFilterId: filter._id,
              });
              handleResponse(response, "Slot filter deleted.", () => {
                onScheduleAdd();
                setActiveFilterDoctors({
                  ...activeFilterDoctors,
                  [`${shiftId}-${index}`]: [],
                });
                setMode("close");
              });
            }}
          >
            <Delete stroke="stroke-pink1" />
          </div>
        ) : (
          !isAutoCorrect && <div className="w-10"></div>
        )}
        {mode === "doctor" && (
          <div
            className={`flex relative cursor-text ${
              isDoctorsLoading ? "animate-pulseFast" : ""
            }`}
            onClick={() => {
              inputRef.current?.focus();
            }}
          >
            {!isDoctorsLoading && (
              <>
                <input
                  className={`bg-lightGreen3 text-black w-[90%] outline-none font-medium text-base`}
                  value={query}
                  ref={inputRef}
                  onChange={(event) => setQuery(event.target.value)}
                />
                {autoCompleteText && (
                  <div className="absolute flex mt-[5px] overflow-hidden">
                    <div
                      className={`font-medium text-base invisible ${
                        query?.[query.length - 1] === " " ? "mr-1" : ""
                      }`}
                    >
                      {query}
                    </div>
                    <div
                      className={`bg-slate-600 text-white font-medium flex items-center text-base w-fit whitespace-nowrap`}
                    >
                      {autoCompleteText}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {!isAutoCorrect && mode === "doctor" && (
          <div className="flex items-center w-[40px] justify-between mr-2">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => {
                setMode("close");
                resetState();
              }}
            >
              <RoundedCancelOutline expand={true} />
            </div>
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() =>
                handleSubmit(() => {
                  onScheduleAdd();
                  setMode("close");
                })
              }
            >
              <RoundedTickOutline expand={true} />
            </div>
          </div>
        )}
      </div>
      {mode === "doctor" &&
        (!isAutoCorrect ? (
          <HorizontalScrollContainer
            className={`mt-4 mb-1 mx-1 !gap-x-1 min-h-[35px]  ${
              isScheduleLabelLoading
                ? "rounded-lg animate-pulseFast bg-lightGreen2"
                : ""
            }`}
          >
            {scheduleLabels?.map(
              (label: { label: string; colorCode: string; _id: string }) => (
                <SlotPill
                  label={{
                    label: label.label,
                    colorCode: label.colorCode,
                    key: label._id,
                  }}
                  isActive={selectedLabels.includes(label._id)}
                  onClick={() => {
                    if (selectedLabels.includes(label._id)) {
                      setSelectedLabels([
                        ...selectedLabels.filter((id) => id !== label._id),
                      ]);
                    } else if (selectedLabels.length < 6) {
                      setSelectedLabels([...selectedLabels, label._id]);
                    }
                  }}
                />
              )
            ) ?? []}
          </HorizontalScrollContainer>
        ) : (
          <div className="h-[43px] w-full"></div>
        ))}
      {mode === "filter" && !isCreateSlotFilterLoading && (
        <FilterSlot
          filterState={filterState}
          setFilterState={setFilterState}
          shiftId={shiftId}
          slotIndex={index}
        />
      )}
      {mode === "doctor" && isAutoCorrect && (
        <div
          className={
            "font-medium text-[10px] overflow-y-scroll no-scrollbar h-20 absolute top-1 right-[8%]"
          }
        >
          {autoCorrectDoctors.map((doctor) => (
            <div
              key={doctor.doctor._id}
              className="bg-white rounded-sm text-black my-1 cursor-pointer w-fit mx-auto p-1"
              onClick={() => {
                setQuery(getName(doctor));
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 0);
              }}
            >
              {getName(doctor)}
            </div>
          ))}
        </div>
      )}
    </motion.div>
  );
};

export default InputSlot;
