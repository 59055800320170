import { InfinitySpin } from "react-loader-spinner";
import { DateTime } from "luxon";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { HiCalendar } from "react-icons/hi";
import { toast } from "react-toastify";

import { useSeniority } from "../../store/seniority.state";
import Calendar from "../../components/Calendar";
import CalenderCard from "../../components/Calender/CalenderCard";
import { useLocation } from "../../store/location.store";
import CalendarSwitcher from "../../components/CalendarSwitcher";
import { useCalendar } from "../../store/calendar.state";
import { useModal } from "../../hooks/useModal";
import Typography from "../../components/Typography";
import NavBar from "../../components/NavBar";
import SuccessModal from "../../components/Modals/SuccessModal";
import { useAutoAssignmentMonthMutation } from "../../store/rosterApi";
import StartAssignmentModal from "../../components/Modals/StartAssignmentModal";
import ReleaseShiftReq from "../../components/Modals/ReleaseShiftReq";
import FinalisedRosterRelease from "../../components/Modals/FinalisedRosterRelease";
import { TIME_ZONE } from "../../constants";
import SideBarLayout from "../../layouts/Sidebar";
import {
  useGetCalendarQuery,
  useGetDayOffsQuery,
  useGetLocationsQuery,
} from "../../store/locationApi";
import DayView from "../../components/Dashboard/DayView";
import { shiftsApi, useGetShiftsByTimingQuery } from "../../store/shiftsApi";
import { formatDateAPI } from "../../utils/formatTime";
import {
  decrementMonth,
  getDateFromHash,
  getHashFromDate,
  incrementMonth,
} from "../../utils/date";
import { useRunOnChangeExt } from "../../hooks/runOnChange";
import { requestsApi } from "../../store/requestsApi";
import { useDispatch } from "react-redux";

const Dashboard = () => {
  const { activeId: activeSeniorityId, setActiveId } = useSeniority();

  const [searchParams, setSearchParams] = useSearchParams();

  const { month, setMonth, year, setYear } = useCalendar();

  const [activeDate, setActiveDate] = useState(
    searchParams.get("dateHash")
      ? DateTime.fromJSDate(getDateFromHash(searchParams.get("dateHash")!))
          .setZone(TIME_ZONE)
          .toJSDate()
      : DateTime.fromObject(
          { day: 1, month, year },
          { zone: TIME_ZONE }
        ).toJSDate()
  );

  const {
    activeId: activeLocationId,
    locations,
    setActiveId: setActiveLocationId,
  } = useLocation();

  const {
    isLoading,
    data: calendarData,
    refetch,
  } = useGetCalendarQuery(
    {
      locationId: activeLocationId.toString(),
      seniority: Number(activeSeniorityId),
      month: month,
      year,
    },
    { skip: !activeLocationId }
  );

  const {
    isLoading: isShiftsByTimingLoading,
    isFetching: isShiftsByTimingFetching,
    data: shiftsByTiming,
  } = useGetShiftsByTimingQuery({ date: formatDateAPI(activeDate) });

  const {
    data: dayOffData,
    isLoading: isDayOffLoading,
    isFetching: isDayOffFetching,
  } = useGetDayOffsQuery({ date: formatDateAPI(activeDate) });

  const { isSuccess, isLoading: isLocationLoading } = useGetLocationsQuery({
    seniority: 4,
    all: true,
  });

  const { openModal, closeModal } = useModal();

  useRunOnChangeExt(locations, () => {
    if (
      searchParams.get("locationId") &&
      searchParams.get("seniority") &&
      searchParams.get("dateHash")
    ) {
      const dateTime = DateTime.fromJSDate(
        getDateFromHash(searchParams.get("dateHash")!)
      ).setZone(TIME_ZONE);
      setMonth(dateTime.month);
      setYear(dateTime.year);
      setActiveId(Number(searchParams.get("seniority")));
      setActiveLocationId(searchParams.get("locationId")!);
    } else if (locations.length) {
      setActiveLocationId(locations[0]._id);
      setSearchParams({
        locationId: activeLocationId,
        seniority: activeSeniorityId.toString(),
        dateHash: getHashFromDate(activeDate),
      });
    }
  });

  const [autoAssign] = useAutoAssignmentMonthMutation();

  const setActiveDateNxtMonth = (month: number, year: number) => {
    const newActiveDate = DateTime.fromObject(
      {
        day: 1,
        month,
        year,
      },
      { zone: TIME_ZONE }
    ).toJSDate();
    setActiveDate(newActiveDate);
    setSearchParams({
      locationId: activeLocationId,
      seniority: activeSeniorityId.toString(),
      dateHash: getHashFromDate(newActiveDate),
    });
  };

  const shouldDisplayRosterAutomation =
    calendarData &&
    calendarData.calendar.every((c: any) => {
      if (c.isClosed) {
        return true;
      }
      if (c.summary) {
        return true;
      }
      return false;
    });

  const getCalendarDate = (dateTime: DateTime) =>
    calendarData?.calendar.find((item: { date: string }) =>
      dateTime.equals(DateTime.fromISO(item.date).setZone(TIME_ZONE))
    );

  const activeDateSummary = getCalendarDate(
    DateTime.fromJSDate(activeDate).setZone(TIME_ZONE)
  );

  const dispatch = useDispatch();

  return (
    <SideBarLayout isCalendarLoading={isLoading}>
      <div className="flex-grow flex flex-col items-center pl-10 pr-5">
        <NavBar activeDate={activeDate} />
        {isLocationLoading && (
          <div className="flex justify-center items-center flex-grow">
            <div>
              <InfinitySpin width="62%" color="#67823A" />
            </div>
          </div>
        )}
        {isSuccess && locations.length && activeLocationId !== "null" && (
          <div className="flex-grow flex w-full gap-5 h-0">
            <div className="flex-grow h-full pb-5 flex flex-col">
              <div className="flex w-full p-4 justify-between">
                <CalendarSwitcher
                  buttonClass="h-6 w-6 bg-primary text-secondary text-xl rounded-lg"
                  textClass="font-semibold whitespace-nowrap text-[2.8rem]"
                  disabledLeft={
                    isLoading ||
                    !activeLocationId ||
                    !activeSeniorityId ||
                    !calendarData
                  }
                  disabledRight={
                    isLoading ||
                    !activeLocationId ||
                    !activeSeniorityId ||
                    !calendarData
                  }
                  month={month}
                  year={year}
                  onNext={() => {
                    const newMonthYear = incrementMonth(month, year);
                    setMonth(newMonthYear.month);
                    if (newMonthYear.year !== year) {
                      setYear(newMonthYear.year);
                    }
                    setActiveDateNxtMonth(
                      newMonthYear.month,
                      newMonthYear.year
                    );
                  }}
                  onPrev={() => {
                    const newMonthYear = decrementMonth(month, year);
                    setMonth(newMonthYear.month);
                    if (newMonthYear.year !== year) {
                      setYear(newMonthYear.year);
                    }
                    setActiveDateNxtMonth(
                      newMonthYear.month,
                      newMonthYear.year
                    );
                  }}
                />

                <div className="flex items-center gap-x-3">
                  <button
                    className="whitespace-nowrap border-secondary border-2 text-secondary font-medium py-2.5 px-6 rounded-2xl"
                    onClick={() => {
                      openModal(
                        <FinalisedRosterRelease
                          onSubmit={async () => {
                            await refetch();
                          }}
                          calendarData={calendarData.calendar}
                        />
                      );
                    }}
                  >
                    Finalized Roster Release
                  </button>
                  {shouldDisplayRosterAutomation ? (
                    <button
                      className="whitespace-nowrap border-secondary border-2 text-secondary font-medium py-2.5 px-6 rounded-2xl"
                      onClick={() => {
                        const autoAssignPromise = autoAssign({
                          location: activeLocationId,
                          seniority: Number(activeSeniorityId),
                          month,
                          year,
                        });
                        openModal(<StartAssignmentModal />);
                        autoAssignPromise
                          .then(() => {
                            dispatch(shiftsApi.util.resetApiState());
                            dispatch(requestsApi.util.resetApiState());
                            closeModal();
                            openModal(
                              <SuccessModal
                                message="Automatic estimation successful!"
                                title="Auto Assignment"
                                isLoading={false}
                              />
                            );
                            refetch();
                          })
                          .catch((err) => {
                            toast.error(
                              err?.response?.data?.message ??
                                "Something went wrong"
                            );
                          });
                      }}
                    >
                      Roster Automation
                    </button>
                  ) : null}
                  <button
                    disabled={
                      isLoading ||
                      !activeLocationId ||
                      !activeSeniorityId ||
                      !calendarData
                    }
                    className="whitespace-nowrap text-white bg-secondary font-medium py-3 px-7 rounded-2xl flex gap-5 items-center"
                    onClick={() => {
                      openModal(
                        <ReleaseShiftReq
                          onSubmit={async () => {
                            await refetch();
                          }}
                          calendarData={calendarData.calendar}
                          deadline={
                            calendarData.deadline
                              ? DateTime.fromISO(calendarData.deadline)
                                  .setZone(TIME_ZONE)
                                  .toJSDate()
                              : undefined
                          }
                        />
                      );
                    }}
                  >
                    <HiCalendar className="scale-150" />
                    Release Shift Request
                  </button>
                </div>
              </div>
              <div className="flex-grow overflow-auto small-scrollbar">
                <Calendar
                  month={month}
                  year={year}
                  className="!gap-6"
                  onDayRender={({ day: date, menuLoc }) => {
                    const dateTime =
                      DateTime.fromJSDate(date).setZone(TIME_ZONE);
                    const day = dateTime.toFormat("EEE");
                    const dateFormatted = dateTime.toFormat("dd");
                    const data = getCalendarDate(dateTime);
                    const dateHash = window.btoa(date.getTime().toString());
                    const summary = data?.summary;

                    return (
                      <CalenderCard
                        key={date.getTime()}
                        menuLoc={menuLoc}
                        onClick={() => {
                          setActiveDate(dateTime.toJSDate());
                          setSearchParams({
                            locationId: activeLocationId,
                            seniority: activeSeniorityId.toString(),
                            dateHash: getHashFromDate(dateTime.toJSDate()),
                          });
                        }}
                        skeleton={
                          isLoading || !activeLocationId || !activeSeniorityId
                        }
                        className={"col-span-1"}
                        isFinalized={data?.isFinalized}
                        isOpenRequest={data?.allowRequestForDay}
                        isActive={dateTime.equals(
                          DateTime.fromJSDate(activeDate).setZone(TIME_ZONE)
                        )}
                        date={date}
                        monthName={dateFormatted}
                        isEstimated={!!data?.summary}
                        isDisabled={data?.isClosed}
                        url={`/dashboard/${dateHash}?locationId=${activeLocationId}&seniority=${activeSeniorityId}`}
                        day={day}
                        summary={summary}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <DayView
              shiftsByTiming={shiftsByTiming}
              dayOff={dayOffData}
              isLoading={isShiftsByTimingLoading || isDayOffLoading}
              isFetching={isShiftsByTimingFetching || isDayOffFetching}
              day={DateTime.fromJSDate(activeDate)
                .setZone(TIME_ZONE)
                .toFormat("EEE")}
              date={DateTime.fromJSDate(activeDate)
                .setZone(TIME_ZONE)
                .toFormat("dd")}
              dateStatus={
                !activeDateSummary?.allowRequestForDay &&
                !activeDateSummary?.isFinalized
                  ? "requests-not-released"
                  : activeDateSummary?.allowRequestForDay
                  ? "requests-released"
                  : "finalised"
              }
            />
          </div>
        )}
        {isSuccess && !locations.length ? (
          <div className="flex flex-col justify-center flex-grow w-full items-center">
            <Typography tag="h3" className="text-black1">
              No locations found. Please add a location to view calendar.
            </Typography>
          </div>
        ) : null}
      </div>
    </SideBarLayout>
  );
};

export default Dashboard;
